body {
  font-family: 'Lora', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
}

html {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h2 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h3 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h4 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h5 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h6 {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 0 0 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 25px;
}

a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #42DCA3;

  &:focus {
    text-decoration: none;
    color: #1d9b6c;
  }

  &:hover {
    text-decoration: none;
    color: #1d9b6c;
  }
}

#mainNav {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: black;

  .navbar-toggler {
    font-size: 14px;
    padding: 11px;
    color: white;
    border: 1px solid white;
  }

  .navbar-brand {
    font-weight: 700;
  }

  a {
    color: white;
  }

  .navbar-nav {
    .nav-item {
      -webkit-transition: background 0.3s ease-in-out;
      -moz-transition: background 0.3s ease-in-out;
      transition: background 0.3s ease-in-out;

      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }

      &:active {
        outline: none;
        background-color: transparent;
      }

      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }
}

.masthead {
  display: table;
  width: 100%;
  height: auto;
  padding: 200px 0;
  text-align: center;
  color: white;
  background: url("../img/typing-code.jpg") no-repeat bottom center scroll;
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .intro-body {
    display: table-cell;
    vertical-align: middle;

    .brand-heading {
      font-size: 50px;
      text-shadow: 2px 2px 4px #000000;
      white-space: pre;
    }

    .intro-text {
      font-size: 18px;
      text-shadow: 2px 2px 4px #000000;
    }
  }
}

.btn-circle {
  font-size: 26px;
  width: 55px;
  height: 55px;
  margin-top: 15px;
  line-height: 45px;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  color: white;
  border: 2px solid white;
  border-radius: 100% !important;
  background: transparent;

  &:focus {
    color: white;
    outline: none;
    background: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    color: white;
    outline: none;
    background: rgba(255, 255, 255, 0.1);
  }
}

.content-section {
  padding-top: 150px;
  padding-bottom: 150px;
}

.download-section {
  color: white;
  background: url("../img/downloads-bg.jpg") no-repeat center center scroll;
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#map {
  width: 100%;
  height: 300px;
}

.btn {
  font-family: 'Cabin', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  border-radius: 0;
}

.btn-default {
  color: #42DCA3;
  border: 1px solid #42DCA3;
  background-color: transparent;

  &:focus {
    color: black;
    border: 1px solid #42DCA3;
    outline: none;
    background-color: #42DCA3;
  }

  &:hover {
    color: black;
    border: 1px solid #42DCA3;
    outline: none;
    background-color: #42DCA3;
  }
}

ul.banner-social-buttons {
  margin-top: 0;
}

footer {
  padding: 50px 0;

  p {
    font-size: 14px;
    margin: 0;
  }
}

img {
  &::selection {
    background: transparent;
  }

  &::-moz-selection {
    background: transparent;
  }
}

img.round-profil {
  border-radius: 50%;
  height: 170px;
  width: auto;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  p {
    font-size: 18px;
    line-height: 1.6;
    margin: 0 0 35px;
  }
  .masthead {
    height: 100%;
    padding: 0;

    .intro-body {
      .brand-heading {
        font-size: 100px;
        text-shadow: 2px 2px 4px #000000;
        white-space: pre;
      }

      .intro-text {
        font-size: 22px;
        text-shadow: 2px 2px 4px #000000;
      }
    }
  }
}

@media (min-width: 992px) {
  #mainNav {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s;
    -moz-transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s;
    transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out, padding-bottom 0.3s;
    letter-spacing: 1px;
    border-bottom: none;
    background: transparent;

    .nav-link.active {
      outline: none;
      background-color: rgba(255, 255, 255, 0.3);

      &:hover {
        color: white;
      }
    }
  }
  #mainNav.navbar-shrink {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: black;
  }
  .content-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  #map {
    height: 350px;
  }
}

@media (max-width: 1199px) {
  ul.banner-social-buttons {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  ul.banner-social-buttons {
    li {
      display: block;
      margin-bottom: 20px;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
