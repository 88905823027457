svg.radial-progress {
  height: auto;
  max-width: 200px;
  padding: 1em;
  transform: rotate(-90deg);
  width: 100%;

  circle {
    fill: rgba(0, 0, 0, 0);
    stroke: #fff;
    stroke-dashoffset: 219.91148575129;
    stroke-width: 10;
  }

  circle.incomplete {
    opacity: 0.25;
  }

  circle.complete {
    stroke-dasharray: 219.91148575129;
  }

  text {
    fill: #fff;
    font: 400 1em/1 'Oswald', sans-serif;
    text-anchor: middle;
  }

  &:nth-of-type(6n+1) {
    circle {
      stroke: #673ab7;
    }
  }

  &:nth-of-type(6n+2) {
    circle {
      stroke: #83e4e2;
    }
  }

  &:nth-of-type(6n+3) {
    circle {
      stroke: #fd6470;
    }
  }

  &:nth-of-type(6n+4) {
    circle {
      stroke: #fca858;
    }
  }

  &:nth-of-type(6n+5) {
    circle {
      stroke: #fddc32;
    }
  }
}

.competences-list {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.competence-element {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;

  h1 {
    font-size: 25px;
    margin-bottom: 8px;
  }

  p {
    font-size: 20px;
    padding-top: 0;
  }
}

.competence-circle {
  width: 20%;
}

.competence-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  width: 80%;
}
