section.projects-section {
  padding-top: 100px;

  div.project {
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 5px;
    }

    a {
      margin-top: 10px;
    }
  }

  div.title {
    margin-bottom: 50px;
  }

  div.image {
    margin-bottom: 10px;
  }

  div.left-content {
    text-align: right;
  }

  div.right-content {
    text-align: left;
  }
}
